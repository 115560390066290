
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, CardBody, CardTitle, Col, Progress, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import profileImg from "../../assets/images/profile-img.png"
import Avatars from "components/Common/Avatar";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { getFirebaseApp } from "helpers/firebase_helper";
import { getProfile } from "helpers/user_profile_api";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { listBots } from "store/bot-trader/actions";
import CurrencyFormat from "components/Common/CurrencyFormat";

const DashboardPage = () => {

    document.title = "Dashboard | Vertex Robot Trading";

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [displayName, setDisplayName] = useState("");
    const [profileId, setProfileId] = useState("");
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const { currentUser } = getAuth(getFirebaseApp());
        if (currentUser) {
            setDisplayName(currentUser.displayName);
        }
        // TODO add to redux
        getProfile().then(data => {
            setProfileId(data.shortId);
            if (data.tasks) {
                setProgress(Math.round((data.tasks.filter(t => t.isComplete).length / data.tasks.length) * 100));
            }
            setLoading(false);
        });

    }, []);

    const BotsProperties = createSelector((state: any) => state.bots, (Bots) => ({
        bots: Bots.bots,
        loading: Bots.loading
    }));
    const {
      bots/*, loading*/
    } = useSelector(BotsProperties);
  
    useEffect(() => {
        console.log("list bots on dash");
        dispatch(listBots());
    }, [dispatch]);

    return (
        <>
            <div className="page-content">
                <Breadcrumbs title="Dashboard" />
                <Row>

                    <Col>

                        <Card className="overflow-hidden">
                            <div className="bg-primary-subtle">
                                <Row>
                                    <Col xs="7">
                                        <div className="text-primary p-3">
                                            <h5 className="text-primary">Welcome !</h5>
                                            <p>Robots Dashboard</p>
                                        </div>
                                    </Col>
                                    <Col xs="5" className="align-self-end">
                                        <img src={profileImg} alt="" className="img-fluid" />
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-0">
                                <Row>
                                    <Col sm="6">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <Avatars size="80" />
                                        </div>
                                        <h5 className="font-size-15 text-truncate pt-2">{displayName}</h5>
                                        <p className="text-muted mb-0 text-truncate">Profile ID: {profileId}</p>
                                    </Col>

                                    <Col sm="6">
                                        <div className="pt-4">
                                            <Row>
                                                <Col xs="6">
                                                    <h5 className="font-size-15">{bots.length}</h5>
                                                    <p className="text-muted mb-0">Robots</p>
                                                </Col>
                                                <Col xs="6">
                                                    <h5 className="font-size-15">
                                                        { <CurrencyFormat isNull={() => bots.length == 0} value={bots.reduce((a, b) => Math.max(a.balance + b.balance), 0)}/> }
                                                    </h5>
                                                    <p className="text-muted mb-0">Balance</p>
                                                </Col>
                                            </Row>
                                            <div className="mt-4">
                                                <Link
                                                    to="/bots"
                                                    className="btn btn-primary  btn-sm">
                                                    My Robots <i className="mdi mdi-arrow-right ms-1"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>


                    </Col>
                    <Col>


                        <Card>
                            <CardBody>
                                <CardTitle className="h4">
                                    <Link to="setup">Set Up Tasks</Link>
                                </CardTitle>
                                <div className="card-title-desc">
                                    {
                                        loading ? 
                                            <div className="d-flex"><Spinner className="m-auto"/></div>
                                        : (

                                            progress == 100 
                                            ? <span>All setup tasks are done <i color="success" className={"text-success fs-4 mdi mdi-check-bold"} /></span> 
                                            : <span>Please complete setup tasks
                                                <Link
                                                    to="/setup"
                                                    className="btn btn-primary btn-sm ms-3">
                                                    Tasks <i className="mdi mdi-arrow-right ms-1"></i>
                                                </Link>
                                            </span>
                                        )
                                    } 
                                </div>
                                <div >
                                    <div className="animated-progess">
                                    <Progress color="success" className="progress-xl fs-6" value={progress}>
                                        {progress}%
                                    </Progress>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )



}

DashboardPage.propTypes = {
    navigate: PropTypes.object,
};

export default withRouter(DashboardPage);


