import { del, get, post, put } from "./api_helper";

// get chats
export const getChats = () => get("/chat/channels");

// get Contacts
export const getContacts = (ids) => post("/chat/lookUpMembers", {members: ids});

// get messages
export const getMessages = (channelId) => get(`/chat/messages/${channelId}`);

export const addMessage = message => post("/chat/message", message);

// delete message
export const deleteMessage = ({channelId, messageId}) => del(`/chat/message/${channelId}/${messageId}`);