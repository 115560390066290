import React, {useState, useEffect} from "react";
import { createCheckoutSession, getPrice, getProduct } from "../../ext-firestore-stripe-payments";
import { getPayments } from "../../helpers/firebase_helper";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Alert,
} from "reactstrap";
import CurrencyFormat from "./CurrencyFormat";
import Spinner from "./Spinner";
import { analytics } from "helpers/analytics_helper";
import { getPromotionId } from "helpers/user_profile_api";

const PaymentForm = ({productId, priceId, priceType}: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [product, setProduct] = useState({name: "Loading...Loading...Loading...",});
  const [price, setPrice] = useState({});
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingProduct, setLoadingProduct] = useState(true);

  const routeUrl = window.location.href.split("/payment")[0];
  
  useEffect(() => {
    const payments = getPayments();
    if (!payments) {
      setErrorMessage("Error retrieving payment information");
      return;
    }
    async function fetchProduct() {
      const product = await getProduct(payments, productId);
      const price = await getPrice(payments, productId, priceId);
      setProduct(product);
      setPrice(price);
      setLoading(false);
      setLoadingProduct(false);

      // https://firebase.google.com/docs/analytics/measure-ecommerce
      const aData = {
        currency: price.currency,
        value: price.unit_amount / 100,
        coupon,
        items: [{
          item_id: price.description,
          price: price.unit_amount / 100,
          item_name: product.name
        }],
        priceId: price.id,
        productId: price.product
      };
      analytics.track('begin_checkout', aData);
    }; 
    fetchProduct();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const payments = getPayments();
    if (!payments) {
      setErrorMessage("Error initialising payments");
      setLoading(false);
      return;
    }

    setErrorMessage("");

    let promotionId = null;
    if (coupon) {
      try {
        promotionId = await getPromotionId(coupon);
      } catch (err) {
        if (err?.response?.data) {
          setErrorMessage(err.response.data.message);
        } else {
          setErrorMessage("Error retrieving voucher code!");
        }
        setLoading(false);
        return;
      }
    }

    const session = await createCheckoutSession(payments, {
      price: priceId,
      mode: priceType == "recurring" ? "subscription" : "payment",
      // allow_promotion_codes: true,
      promotion_code: promotionId,
      metadata: {"coupon": coupon},
      success_url: `${routeUrl}/payment-success/${productId}/${priceId}`
    });

    window.location.assign(session.url);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardBody>
          <Row>
            <Col xs={9} sm={6}>
                <div className={`${loadingProduct && "placeholder"}`}>{product.name}</div>
                <p className={`text-muted`}>{product.description}</p>
            </Col>
            <Col xs={9} sm={3}>
              {price.type == "one_time" && "(Single payment)"}
              {price.type == "recurring" && `(Recurring per ${price.interval})`}
            </Col>
            <Col xs={3} sm={3} className="text-end">
              <CurrencyFormat isNull={()=>!price.unit_amount} value={(price.unit_amount / 100)} currency={price.currency}/>
            </Col>
          </Row>
          <Row>
            <Col className="align-top mt-4">
              <h6 className="m-0 text-end">
                Total: <CurrencyFormat isNull={()=>!price.unit_amount} value={(price.unit_amount / 100)} currency={price.currency}/>
              </h6>
            </Col>
          </Row>
          <Row>
            { errorMessage && <Alert color="danger"
                      className="alert-dismissible fade show"
                      role="alert">
              <i className="mdi mdi-block-helper me-2"></i>
              {errorMessage}
            </Alert> }
          </Row>

          
        </CardBody>
      </Card>

      <Row className="mb-3">
        <div className="col-md-4"></div>
          <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Coupon Code
            </label>
            <div className="col-md-6">
              <input
                className="form-control"
                type="text"
                placeholder="Enter discount code"
                onChange={e => setCoupon(e.target.value)} value={coupon}
              />
            </div>
                    
                  </Row>

      <div className="d-grid gap-2">
        


        <Button color="primary" className="btn btn-primary btn-block" disabled={loading} type="submit">
          Checkout {loading && <Spinner isButton={true}/>}
        </Button>
      </div>

    </form>
  );
};

export default PaymentForm;
