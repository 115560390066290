import { takeEvery, put, call, select } from "redux-saga/effects";

// Chat Redux States
import {
  DELETE_MESSAGE,
  GET_CHATS,
  GET_CONTACTS,
  GET_MESSAGES,
  ADD_MESSAGE,
  RECIEVE_MESSAGE,
  SELECT_CHANNEL,
  RECIEVED_USER_STATUS,
} from "./actionTypes";
import {
  getChatsSuccess,
  getChatsFail,
  getContactsSuccess,
  getContactsFail,
  getMessagesSuccess,
  getMessagesFail,
  addMessageSuccess,
  addMessageFail,
  deleteMessageSuccess,
  deleteMessageFail,
  recieveMessageSuccess,
  selectChannelSuccess,
  recieveUserStatus,
  recieveUserStatusSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getChats,
  getContacts,
  getMessages,
  addMessage,
  deleteMessage,
} from "../../helpers/chat_api";
import { toast } from "react-toastify";

const selectContacts = (state) => {
  return state.chats.contacts;
}

const selectUser = (state) => {
  return state.login.user;
}

function* onGetChats() {
  try {
    const response = yield call(getChats);
    yield put(getChatsSuccess(response));
    yield onGetContacts();
  } catch (error) {
    yield put(getChatsFail(error));
  }
}

function* onGetContacts() {
  try {
    const contacts = yield select(selectContacts);
    const user = yield select(selectUser);
    console.log("onGetContacts", user);
    const toLoad = [];
    for (const id of Object.keys(contacts)) {
      if (!contacts[id]?.isLoaded) {
        toLoad.push(id);
      }
    }
    if (toLoad.length > 0) {
      const response = yield call(getContacts, toLoad);
      yield put(getContactsSuccess(response, user));
    }
  } catch (error) {
    yield put(getContactsFail(error));
  }
}

function* onGetMessages({ channelId }) {
  try {
    const response = yield call(getMessages, channelId);
    yield put(getMessagesSuccess(response));
  } catch (error) {
    yield put(getMessagesFail(error));
  }
}

function* onAddMessage({ payload: message }) {
  try {
    const response = yield call(addMessage, message);
    yield put(addMessageSuccess(response));
  } catch (error) {
    yield put(addMessageFail(error));
  }
}

function* onRecieveMessage({ payload: message }) {
  yield put(recieveMessageSuccess(message));
}

function* onRecieveUserStatus({ payload: data }) {
  const user = yield select(selectUser);
  yield put(recieveUserStatusSuccess(data, user));
}

function* onSelectChannel({ payload: channel }) {
  yield put(selectChannelSuccess(channel));
}

function* OnDeleteMessage({ payload: data }) {
  try {
    const response = yield call(deleteMessage, data)
    yield put(deleteMessageSuccess(response))
    toast.success("Message Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteMessageFail(error))
    toast.error("Message Deleted Failed", { autoClose: 2000 });
  }
}

function* chatSaga() {
  yield takeEvery(GET_CHATS, onGetChats);
  yield takeEvery(GET_CONTACTS, onGetContacts);
  yield takeEvery(RECIEVED_USER_STATUS, onRecieveUserStatus);
  yield takeEvery(GET_MESSAGES, onGetMessages);
  yield takeEvery(ADD_MESSAGE, onAddMessage);
  yield takeEvery(SELECT_CHANNEL, onSelectChannel);
  yield takeEvery(RECIEVE_MESSAGE, onRecieveMessage);
  yield takeEvery(DELETE_MESSAGE, OnDeleteMessage);

}

export default chatSaga;

