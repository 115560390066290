import { 
  EDIT_PROFILE_ERROR, 
  EDIT_PROFILE_SUCCESS, 
  EDIT_PROFILE, 
  RESET_PROFILE_FLAG, 
  GET_PROFILE_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_ERROR
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
  profile: null, 
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
    case GET_PROFILE:
      state = { ...state, loading: true };
      break;
    case EDIT_PROFILE_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case EDIT_PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_PROFILE_SUCCESS:
      state = { ...state, profile: action.payload, loading: false };
      break;
    case GET_PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null, loading: false };
      break;
  }
  return state;
};

export default profile;
