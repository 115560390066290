import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE } from "./actionTypes"
import { editProfileSuccess, editProfileError, getProfileSuccess, getProfileError } from "./actions"

import {
  getProfile,
} from "helpers/user_profile_api";

function* onGetProfile() {
  try {
    const response = yield call(getProfile)
    yield put(getProfileSuccess(response))      
  } catch (error) {
    yield put(getProfileError(error))
  }
}

function* profileSaga() {
  // yield takeEvery(EDIT_PROFILE, onEditProfile);
  yield takeEvery(GET_PROFILE, onGetProfile);
}

export default profileSaga
