import store from "store";
import {
  GET_CHATS_SUCCESS,
  GET_CHATS_FAIL,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAIL,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS,
  GET_CHATS,
  GET_CONTACTS,
  GET_MESSAGES,
  ADD_MESSAGE,
  RECIEVE_MESSAGE_SUCCESS,
  SELECT_CHANNEL_SUCCESS,
  RECIEVED_USER_STATUS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  chats: [],
  currentChannel: {id: null},
  contacts: {},
  messages: [],
  error: {},
  loadingChannels: true,
  loadingContacts: true,
  loadingMessages: false,
  sendingMessage: false,
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CHATS:
      return {
        ...state,
        loadingChannels: true,
      };
    case GET_CONTACTS:
      return {
        ...state,
        loadingContacts: true,
      };
    case GET_MESSAGES: 
      return {
        ...state,
        loadingMessages: true,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        sendingMessage: true,
      };


    case GET_CHATS_SUCCESS:

      return {
        ...state,
        chats: action.payload,
        contacts: action.payload.flatMap(channel => [...channel.members, ...channel.admins])
                    .reduce((aggr, value) => { aggr[value] = {id: value, isLoaded: false}; return aggr; }, 
                            {...state.contacts}),
        loadingChannels: false
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case SELECT_CHANNEL_SUCCESS: 
      return {
        ...state,
        currentChannel: action.payload
      }

    case GET_CONTACTS_SUCCESS: {
      const contacts = action.payload.reduce((aggr, value) => { 
        aggr[value.id] = {...value, isLoaded: true}; return aggr; 
      }, {...state.contacts});
      const chats = [...state.chats];

      updateChannelContacts(chats, contacts, action.user);

      return {
        ...state,
        contacts,
        chats,
        loadingContacts: false
      }
    }

    case GET_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RECIEVED_USER_STATUS_SUCCESS: {

      const contacts = [action.payload].reduce((aggr, value) => {
        if (aggr[value.id]) {
          aggr[value.id] = {...aggr[value.id], ...value}; 
        } 
        return aggr;
      }, {...state.contacts});
      const chats = [...state.chats];

      updateChannelContacts(chats, contacts, action.user);

      return {
        ...state,
        contacts,
        chats
      }
    }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        loadingMessages: false
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MESSAGE_SUCCESS: {   
      const chats = [...state.chats].map(c => {
        if (c.id == state.currentChannel.id) {
          c.lastMessage = action.payload;          
        }
        return c;
      });     
      return {
        ...state,
        messages: [...state.messages.filter(m => !m.isNew), action.payload],
        chats,
        sendingMessage: false
      }
    }
    case ADD_MESSAGE_FAIL:
      return {
        ...state,
        messages: [...state.messages.map(m => m.isNew ? {...m, isFailed: true} : m)],
        error: action.payload,
        sendingMessage: false
      }

    case RECIEVE_MESSAGE_SUCCESS: {   
      const chats = [...state.chats].map(c => {
        if (c.id == action.payload.channelId) {
          c.lastMessage = action.payload.newMessage;          
        }
        return c;
      });  
      if (state.currentChannel.id == action.payload.channelId) {
        return {
          ...state,
          chats,
          messages: [...state.messages, action.payload.newMessage],
        }
      }
      return {
        ...state,
        chats
      }
    }

    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.filter(data => data.id !== action.payload.messageId)
      };
    case DELETE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state
  }
}

function updateChannelContacts(channels: any[], contacts: any[], user: any) {
  
  for (const channel of channels) {
    const userIdsNotMe = channel.members.filter(m => m != user.uid);
    const memberContacts = [];
    memberContacts.push({
        uid: channel.id,
        name: channel.name,
        profileUrl: channel.profileUrl,
        status: "ON-LINE",
        isVisible: userIdsNotMe.length == 0
    });
    userIdsNotMe.concat(channel.admins).forEach(mId => {
        const contact = contacts[mId];
        if (contact) {
          memberContacts.push({
              uid: contact.id,
              name: contact.displayName || contact.email,
              profileUrl: contact.photoURL,
              status: contact.status || "OFF-LINE",
              isVisible: channel.admins.indexOf(mId) == -1
          });
        }
    });
    channel.contacts = memberContacts;
  }

}

export default Chat

