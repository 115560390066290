import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import Spinner from "components/Common/Spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Avatars from "components/Common/Avatar";
import { editUser } from "../../store/actions";

const UserProfile = () => {

  //meta title
  document.title = "Profile | Vertex Robot Trading";

  const dispatch = useDispatch();

  const selectLoginState = (state) => state.login;
  
  const ProfileProperties = createSelector(
      selectLoginState,
        (login) => ({
          user: login.user,
          success: login.success,
          loading: login.loading,
          error: login.error,
        })
    );

  const {
      user,
      success,
      loading,
      error,
  } = useSelector(ProfileProperties);

  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [idx, setIdx] = useState("");

  useEffect(() => {
    if (!user || loading) {
      return;
    }
    setDisplayName(user.displayName);
    setEmail(user.email);
    setIdx(user.uid);
  }, [loading]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      displayName: displayName || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      displayName: Yup.string().required("Please Enter Your Full Name"),
    }),
    onSubmit: (values) => {
      dispatch(editUser(values));
    }
  });


  return (
    <React.Fragment>           
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12"> 
              {error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <Avatars name={displayName} size="80"/>
                    </div>
                    <div className="flex-grow-1 align-self-center ms-5">
                      <div className="text-muted">
                        <h5>{displayName}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0"><span className="fw-bold">User ID:</span> {idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Details</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Full Name</Label>
                  <Input
                    name="displayName"
                    className="form-control"
                    placeholder="Enter Your Full Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.displayName || ""}
                    invalid={
                      validation.touched.displayName && validation.errors.displayName ? true : false
                    }
                  />
                  {validation.touched.displayName && validation.errors.displayName ? (
                    <FormFeedback type="invalid">{validation.errors.displayName}</FormFeedback>
                  ) : null}
                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger" disabled={loading}>
                    Update User Details
                    {loading && <Spinner isButton={true}/>}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
