import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { map } from "lodash";
import {
  Col,
  Container,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

// simple bar
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

import {
  getChats as onGetChats,
  recieveMessage as onRecieveMessage,
  recieveUserStatus as onRecieveUserStatus,
  selectChannel as onSelectChannel,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";

import ChatMessages from "./ChatMessages";
import { getFirebaseBackend } from "helpers/firebase_helper";
import ChatAvatar from "./ChatAvatar";
import { getAuth } from "firebase/auth";
import { getFirebaseApp } from "helpers/firebase_helper";

const Chat = () => {

  //meta title
  document.title = "Chat | Vertex Robot Tading";

  const dispatch = useDispatch();


  const selectUserState = (state) => state.login;
  const UserProperties = createSelector(selectUserState, (login) => ({currentUser: login.user}));
  const { currentUser } = useSelector(UserProperties);

  const selectChatState = (state) => state.chats;
  const ChatProperties = createSelector(
    selectChatState,
    (chats) => ({
      chats: chats.chats,
      currentChannel: chats.currentChannel,
      loading: chats.loadingContacts || chats.loadingChannels
    })
  );
  const { chats, currentChannel, loading } = useSelector(ChatProperties);
  const [ channelsOrdered, setChannelsOrdered ] = useState([]);

  useEffect(() => {
    if (currentUser) {
      dispatch(onGetChats());
    }
  }, [onGetChats, currentUser]);

  const STATUS_COMPARE = {"ON-LINE": "0", "OFF-LINE": "1", "SIGNED-OUT": "1"};
  useEffect(() => {
    let hasAllContacts = true;
    setChannelsOrdered([]);
    const list = chats.sort((a, b) => {
      if (!a.contacts || !b.contacts) {
        hasAllContacts = false;
        return 0;
      }
      const cA = a.contacts.filter(c => c.isVisible).at(-1);
      const cB = b.contacts.filter(c => c.isVisible).at(-1);
      if (!a.lastMessage && !b.lastMessage) {
        return STATUS_COMPARE[cA.status].concat(cA.name)
                    .localeCompare(STATUS_COMPARE[cB.status].concat(cB.name));
      }
      return (a.lastMessage && b.lastMessage ? b.lastMessage?.sentAt?.localeCompare(a.lastMessage?.sentAt) : (a.lastMessage ? -1 : 1));
    }); 
    if (hasAllContacts) {
      setChannelsOrdered(list);
    }
  }, [chats]);

  const userChannelOpen = (channel) => {
    dispatch(onSelectChannel(channel));
  };

  const setUpRealtimeData = () => {
    const unsubscripeUserStatus = getFirebaseBackend().onUserStatus((snapshot: any) => {
      if (snapshot.val() == false) {
        return;
      };
      const data = snapshot.val();
      dispatch(onRecieveUserStatus({id: snapshot.key, ...data}));
    });

    const unsubscripeUserChannels = getFirebaseBackend().onRealtimeChildData("channel", (snapshot: any) => {
      if (snapshot.val() == false) {
        return;
      };
      const data = snapshot.val();
      dispatch(onRecieveMessage({channelId: snapshot.key, newMessage: data.newMessage}));
    });

    return () => {
      unsubscripeUserStatus();
      unsubscripeUserChannels();
    };
  }

  useEffect(() => {
    setUpRealtimeData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Chat" items={[DASHBOARD_ITEM]} />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div >

                    <div className="chat-leftsidebar-nav position-relative">
                 
                      
                          <div>
                            <h5 className="font-size-14 mb-3">ID: {currentChannel.id}</h5>
                            <ul className="list-unstyled chat-list" id="recent-list">
                              {
                                loading ? <Spinners /> :
                                  // <SimpleBar style={{ maxHeight: "410px" }}>
                                  <SimpleBar>
                                    {map(channelsOrdered, channel => (
                                      <li
                                        key={channel.id + channel.status}
                                        className={ 
                                          currentChannel.id === channel.id
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <Link className="p-0" to="#" onClick={() => { userChannelOpen(channel);}}>
                                          <ChatAvatar channel={channel} currentUser={currentUser} isHeader={false}/>
                                        </Link>
                                      </li>
                                    ))}
                                  </SimpleBar>
                              }
                            </ul>
                          </div>

                            {/* <SimpleBar style={{ height: "410px" }}>
                              {contacts &&
                                contacts.map(contact => (
                                  <div
                                    key={"test_" + contact.category}
                                    className={
                                      contact.category === "A" ? "" : "mt-4"
                                    }
                                  >
                                    <div className="avatar-xs mb-3">
                                      <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {contact.category}
                                      </span>
                                    </div>

                                    <ul className="list-unstyled chat-list">
                                      {contact.child.map(array => (
                                        <li key={"test" + array.id} className={currentRoomId === array.roomId ? "active" : ""}>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              userChatOpen(
                                                array
                                              );
                                            }}
                                          >
                                            <h5 className="font-size-14 mb-0">
                                              {array.name}
                                            </h5>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                            </SimpleBar> */}
                    </div>
                  </div>
                </div>
                <ChatMessages channel={currentChannel} currentUser={currentUser} />
              </div>
            </Col>
          </Row >
        </Container >
      </div >
    </React.Fragment >
  );
};

Chat.propTypes = {
  chats: PropTypes.array,
  onGetChats: PropTypes.func,
};

export default Chat;
