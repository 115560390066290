import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import profile from "./auth/profile/reducer";


import bots from "./bot-trader/reducer";
import chats from "./chat/reducer";

const rootReducer = combineReducers({
  // public
  bots,
  chats, 
  login,
  profile,
  Layout,
  Account,
  ForgetPassword,
});

export default rootReducer;
