import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Input, Label, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { BROKER_IMAGES } from "./BrokerImages";
import { updateProfile } from "helpers/user_profile_api";
import { useNavigate } from "react-router-dom";
import GetStartedCard from "./GetStartedCard";
import { withTranslation } from "react-i18next";
import { isDownloadEnabled } from "helpers/config";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const StepStart = ({ t, data, tasks, onSelection, colWidths, nextStep, help }) => {

    const [taskData, setTaskData] = useState(tasks);

    const navigate = useNavigate();

    const onComplete = (taskIndex) => {
        tasks[taskIndex].isComplete = true;
        updateProfile({tasks});
        setTaskData([...tasks]);
    };

    const BotsProperties = createSelector((state: any) => state.bots, (bots) => ({
        bots: bots.bots,
        loading: bots.loading
    }));
    const {
      bots, loading
    } = useSelector(BotsProperties);

    useEffect(() => {
        if (loading) {
            return;
        }
        tasks.forEach((task, index) => {
            if (task.type == "new-robot" && !task.isComplete) {
                bots.then((robots) => {
                    if (robots.length > 0) {
                        onComplete(index);
                    }
                });
            }
            if (task.type == "run-robot" && !task.isComplete) {
                bots.then((robots) => {
                    if (robots.filter(r => (r.state == "RUNNING" || r.isDownloaded)).length > 0) {
                        onComplete(index);
                    }
                });
            }
        });
    }, [loading]);

    return (

        <React.Fragment>
            {!data.isSurveyComplete && <GetStartedCard data={data} onSelection={() => onSelection(nextStep)} />}
            {data.isSurveyComplete &&
            <Card>
                <CardBody className="container-fluid">

                    <h4 className="fs-3 card-title mb-3">Tasks</h4>

                    {
                        taskData.map((task, index) => (
                            <Row key={"task-" + index} className="pb-3 pt-3 border-bottom align-items-center">
                                <Col sm="3" md="2" className="text-center">
                                    { task.type == "vertex-robots" 
                                        ? <i className="fas fa-robot fs-1"></i> 
                                        : <img style={{width: "70px"}} src={BROKER_IMAGES[task.type]} />
                            
                                    }
                                </Col>
                                <Col sm="9" md="8">

                                    <h5 className="text-truncate fs-5 mb-1">
                                        <Link to="#" className="text-dark">
                                            {task.title}
                                        </Link>
                                    </h5>
                                    <p className="text-muted mb-0 fs-4" dangerouslySetInnerHTML={{ __html: task.description }}/> 

                                </Col>
                                <Col md="2" className="text-end pt-2">
                                    <div className="d-grid">
                                        { 
                                            task.isComplete 
                                            ?  <i color="success" className="mdi text-primary fs-1 mdi-check-bold m-auto"/>
                                            : ( task.type == "new-robot" 
                                                ? <Link to="/bots/new/choose" className="btn btn-primary btn-block">Go</Link>
                                                : <Button onClick={() => onComplete(index)} className="btn btn-success">Done</Button>
                                            )  
                                        }
                                    </div>

                                </Col>
                            </Row>
                        ))
                    }

                    {isDownloadEnabled && <Row className="m-3">
                        <Alert color="success" className="fs-5" role="alert">
                            {t("Download Alert")}
                        </Alert>
                    </Row>}

                </CardBody>
            </Card>
            }
        </React.Fragment>
    );
};

export default withTranslation()(StepStart);