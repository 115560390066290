import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col, Container, Row,
  Spinner
} from "reactstrap";

import StepHaveBroker from "./StepHaveBroker";
import StepExistingBroker from "./StepExistingBroker";
import StepAreYouAnAffilate from "./StepAreYouAnAffilate";
import StepExistingBrokerAllSetup from "./StepExistingBrokerAllSetup";
import StepCountry from "./StepCountry";
import StepRecomendedBroker from "./StepRecomendedBroker";
import StepStart from "./StepStart";
import StepExperience from "./StepExperience";
import StepAccountSize from "./StepAccountSize";
import StepNotAnAffilate from "./StepNotAnAffilate";
import { getProfile, updateProfile } from "helpers/user_profile_api";
import { isDownloadEnabled } from "helpers/config";
import { analytics } from "helpers/analytics_helper";
import { getFirebaseBackend } from "helpers/firebase_helper";

export enum Step {
  START = "START",
  HAVE_BROKER = "HAVE_BROKER",
  SELECT_EXISTING_BROKER = "SELECT_EXISTING_BROKER",
  COUNTRY = "COUNTRY",
  ARE_YOU_AN_AFFILATE = "ARE_YOU_AN_AFFILATE",
  RECOMENDED_BROKER = "RECOMENDED_BROKER",
  EXISTING_BROKER_ALL_SETUP = "EXISTING_BROKER_ALL_SETUP",
  EXPERIENCE = "EXPERIENCE",
  ACCOUNT_SIZE = "ACCOUNT_SIZE",
  NOT_AN_AFFILATE = "NOT_AN_AFFILATE"
}

const navigate = {
  START: { nextStep: Step.EXPERIENCE },
  EXPERIENCE: { nextStep: Step.ACCOUNT_SIZE, backStep: Step.START },
  ACCOUNT_SIZE: { nextStep: Step.COUNTRY, backStep: Step.EXPERIENCE },
  COUNTRY: { nextStep: Step.HAVE_BROKER, backStep: Step.ACCOUNT_SIZE },
  HAVE_BROKER: { nextStep: [Step.SELECT_EXISTING_BROKER, Step.RECOMENDED_BROKER], backStep: Step.COUNTRY },
  SELECT_EXISTING_BROKER: { nextStep: Step.ARE_YOU_AN_AFFILATE, backStep: Step.HAVE_BROKER },
  ARE_YOU_AN_AFFILATE: { nextStep: [Step.EXISTING_BROKER_ALL_SETUP, Step.NOT_AN_AFFILATE], backStep: Step.SELECT_EXISTING_BROKER },
  NOT_AN_AFFILATE: { nextStep: Step.COUNTRY, backStep: Step.ARE_YOU_AN_AFFILATE },
  EXISTING_BROKER_ALL_SETUP: {nextStep: Step.START, backStep: Step.ARE_YOU_AN_AFFILATE},
  RECOMENDED_BROKER: { nextStep: Step.START, backStep: Step.HAVE_BROKER },
}

const help: any = {
  START: {},
  EXPERIENCE: {
    title: "Questions", 
    items: [
      {title: "Info", text: "Tell us a bit about yourself."},
      {title: "Why?", text: "Completing this survey will help us understand your goals, so you get the best experience."},
    ]},
  ACCOUNT_SIZE: {},
  HAVE_BROKER: {
    title: "Brokers", 
    items: [
      {title: "What is a broker", text:  "A broker is a professional intermediary who facilitates transactions between buyers and sellers, typically in financial markets. Acting on behalf of their clients, brokers execute trades for stocks, bonds, commodities, or other assets according to the clients instructions."},
      {title: "Do i need a broker", text: "In order to trade in the forex market using automated trading you need a broker that allows for robot compatabillity, our selected list of brokers fully support all your needs for automated trading."},
    ]
  },
  SELECT_EXISTING_BROKER: {},
  ARE_YOU_AN_AFFILATE: {},
  NOT_AN_AFFILATE: {},
  EXISTING_BROKER_ALL_SETUP: {},
  COUNTRY: {},
  RECOMENDED_BROKER: {},
}

// const colWidths = [];


const SetUp = (props) => {
  const [surveyData, setSurveyData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [step, setStep] = useState(Step.START);
  const [colWidths, setColWidths] = useState([]);
  const [loading, setLoading] = useState(true);

  const onSelection = (nextStep: Step, data?: any, tasks?: any) => {
    if (data) {
      let survey = { ...surveyData, ...data, step };
      if (data.isSurveyComplete) {
        tasks = [...tasks, ...createTasks(survey)];
      }
      setSurveyData(survey);
      setTasks(tasks);
      updateProfile({survey, ...tasks && {tasks} });
      analytics.identify(getFirebaseBackend().getCurrentUser().uid, survey);
    }
    if (help[nextStep].title) {
      setColWidths([{ md: "8" }, { md: "4" }]); 
    } else {
      setColWidths([{ md: "12" }, {}]);
    }
    setStep(nextStep);
  };

  const createTasks = (data: any) => {
    let list = [];
    if (Number(data.investmentSize) <= 5000) {
        list.push({type: data.brokerId, title: "Create MT4 cent trading account", description: `Open a MT4 cent trading account with leverage 1:500`, isComplete: false });
        list.push({type: data.brokerId, title: "Deposit funds", description: `Fund your cent trading account with a minimum of $500`, isComplete: false });
    } else {
        list.push({type: data.brokerId, title: "Create MT4 standard trading account", description: `Open a MT4 standard trading account with leverage 1:500`, isComplete: false });
        list.push({type: data.brokerId, title: "Deposit funds", description: `Fund your standard trading account with a minimum of $5000`, isComplete: false });
    }

    list.push({type: "new-robot", title: "Create automated trading robot", description: `With your trading Account ID and Password create an automated trading robot`, isComplete: false });
    list.push({type: "run-robot", title: `Run${isDownloadEnabled ? " or Download" : ""} your robot`, description: `Run using our free 24/7 cloud service${isDownloadEnabled ? " or download and run in your own MetaTrader 4 software" : ""}`, isComplete: false });
    return list;
  }

  useEffect(() => {
      getProfile().then(data => {
        if (data.survey) {
          setSurveyData(data.survey);
        }        
        if (data.tasks) {
          setTasks(data.tasks);
        }
        setLoading(false);
      });
  }, []);

  return (

        <Container fluid>
          <Row className="justify-content-center">
            <Col xxl="11">
              { loading ? <Row><Card className="p-5"><CardBody className="m-auto p-5"><Spinner/></CardBody></Card></Row> :
                <Row>
                  {step == Step.START && <StepStart onSelection={onSelection} key={step} data={surveyData} tasks={tasks} colWidths={colWidths} nextStep={navigate[step].nextStep} help={help[step]}/>}
                  {step == Step.EXPERIENCE && <StepExperience onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.ACCOUNT_SIZE && <StepAccountSize onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.HAVE_BROKER && <StepHaveBroker onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.SELECT_EXISTING_BROKER && <StepExistingBroker onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.COUNTRY && <StepCountry onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.ARE_YOU_AN_AFFILATE && <StepAreYouAnAffilate onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.NOT_AN_AFFILATE && <StepNotAnAffilate onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.RECOMENDED_BROKER && <StepRecomendedBroker onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.EXISTING_BROKER_ALL_SETUP && <StepExistingBrokerAllSetup onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                </Row>
              }
              {/* <Row>
              {step}
              </Row>
              <Row>
                {JSON.stringify(surveyData, null, 4)}
              </Row>
              <Row>
                {JSON.stringify(tasks, null, 4)}
              </Row> */}
            </Col>
          </Row>
        </Container>
  );
};

SetUp.propTypes = {
  navigate: PropTypes.object,
};

export default SetUp;