import CurrencyFormat from "components/Common/CurrencyFormat";
import Spinner from "components/Common/Spinner";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Badge, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from "reactstrap";
import { deleteBot, updateBot } from "store/actions";
import LoginModal from "./LoginModal";

const BotsTable = ({ botsData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const [drpLink, setDrpLink] = useState("");
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [loginModalData, setLoginModalData] = useState({});

    const openUpdateLoginModal = (bot) => {
      setLoginModalData(bot);
      setOpenLoginModal(true);
    }
  
    const displayState = (bot) => {
      if (bot.errorCode) {
        return "";
      }
      if (bot.isDownload) {
        return "LOCAL / DOWNLOADED";
      }
      return bot.state;
    }
    const getStateNext = (bot: any) => {
      return canStop(bot.state) ? "Stop" : "Start";
    }
  
    const isDisabled = (bot: any) => {
      return !(canStart(bot.state) || canStop(bot.state))
    }
  
    const canStart = (state: string): boolean => {
      return ["DEPLOYED", "STOPPED"].indexOf(state) >= 0;
    }
    
    const canStop = (state: string): boolean => {
      return ["RUNNING", "STARTED"].indexOf(state) >= 0;
    }
  
    const onStartStopBot = (bot: any) =>  {
      if (canStop(bot.state)) {
        dispatch(updateBot("STOP", bot, {}));
      } else if (canStart(bot.state)) {
        dispatch(updateBot("START", bot, {}));
      }
    }
  
    const onDeleteBot = (bot: any) =>  {
      dispatch(deleteBot(bot));
    }
  
    const setRunTime = () => {
      for (const bot of botsData) {
        bot.runTime =  moment(new Date(bot.createdAt)).fromNow();
      }
    }
    
    setInterval(setRunTime, 10000);
    setRunTime();
  
    return (
      <div className="table-responsive">
        <LoginModal open={openLoginModal} setOpen={setOpenLoginModal} data={loginModalData}/>
        <Table className="table table-hover mb-0">
          <thead className="table-light">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Status</th>
              <th>Open Trades</th>
              <th>Balance</th>
              <th>Runtime</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="pb-4">
            {botsData.map((bot: any) => (     
              <tr key={bot.id}>
                <td onClick={() => navigate(`/bot/${bot.id}`)}>
                  <div className="position-relative avatar-lg mb-1">
                    <img
                        src={require(`/src/assets/images/bots/thumbnails/ea-${bot.eaId}.png`)}
                        className="z-0 hover-select rounded-4 img-thumbnail border-dark"
                        alt={bot.name}
                      />
                    <Badge color="dark" className="p-2 mb-n2 me-n2 position-absolute bottom-0 end-0 z-1">
                      {bot.symbol && bot.symbol}
                      {bot.period && <span className="ms-1">({bot.period})</span>}
                    </Badge>
                  </div>
                  
                </td>
                {/* <th scope="row" onClick={() => navigate(`/bots/${bot.id}`)}>{bot.name}</th> */}
                <td>
                  <strong>{bot.name}</strong>
                  <p>{bot.description}</p>
                  <p className="text-muted">Account: {bot.login}</p>
                  {bot.instanceId && <p className="text-muted">Instance: {bot.instanceId}</p>}
                </td>
                <td>
                  {bot.state === "LOADING" || bot.state === "REFRESHING" 
                  ? <Spinner isButton={true} status={bot.state}/> 
                  : displayState(bot) }
                  {
                    bot.errorCode == "LOGIN_FAILED" 
                      &&  
                        <div className="d-flex">
                          <Alert
                          color="danger"
                          className="p-1 xalert-dismissible fade show"
                          role="alert">
                            <i className="mdi mdi-block-helper me-2"></i>Login to broker failed!
                          <Button onClick={()=> openUpdateLoginModal(bot)} color="danger" outline className="ms-2">Update Login</Button>
                          </Alert>
                        </div>
                  }
                </td>
                <td>
                  { 
                    bot.equity 
                    ? <><CurrencyFormat isNull={()=>!bot.equity} value={bot.equity} currency={bot.currency}/>
                        <span className="ms-1">({bot.positionCount || 0})</span></>
                    : <div> - </div>
                  }
  
                </td>
                <td><CurrencyFormat isNull={()=>!bot.balance} value={bot.balance} currency={bot.currency}/></td>
                <td>{bot.isDownload ?  " - " : bot.runTime}</td>
                <td>
                  <ButtonDropdown
                      isOpen={drpLink === bot.id}
                      toggle={() => setDrpLink(drpLink === bot.id ? "": bot.id)}
                    >
                      {!bot.isDownload && <Button onClick={() => onStartStopBot(bot)} id="caret" color="primary" disabled={isDisabled(bot)}>
                        {getStateNext(bot)}
                      </Button>}
                      {bot.isDownload && <Link to={`/bots/download/${bot.id}`} id="caret" className="btn btn-primary">
                        Downlaod
                      </Link>}
                      <DropdownToggle caret color="primary">
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => onDeleteBot(bot)}>Delete</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  export default BotsTable;