
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import ChatSenderName from "./ChatSenderName";


const ChatAvatar = ({ channel, currentUser, isHeader }: {channel: any, currentUser: any, isHeader: boolean}) => {

    const [ members, setMembers ] = useState([]);

    const getProfileUrl = (channel) => {
        if (channel.profileUrl?.startsWith("/")) {
            return process.env.PUBLIC_URL + channel.profileUrl;
        }
        return channel.profileUrl;
    }

    const getStatus = (member) => {
        if (!member) {
            return "";
        }
        return member.status == "ON-LINE" ? "Online" : "Offline";
    }
    const getStatusClass = (member) => {
        return member?.status == "ON-LINE" ? "bg-success" : "bg-offline";
    }

    useEffect(() => {
        if (!channel.id || !channel.contacts) {
            return;
        }
        setMembers(channel.contacts.filter(c => c.isVisible));
    }, [channel.id, channel.contacts]);

    return <>
        {false && 
        <div>
            {members.map(m => (
                <span key={"ah"+m.uid} className="text-muted mb-0">
                    <i
                        className={
                            m?.status === "ON-LINE"
                                ? "mdi mdi-circle text-success align-middle me-2"
                                : "mdi mdi-circle align-middle me-1"
                        }
                    />
                    {m.name}
                </span>
            ))}
            
        </div>}
        {true && 
        <div className="d-flex p-1">
            {!isHeader && <div className="position-relative z-1 align-self-end me-3 p-0">
                {members.map((m, index) => 
                    <span key={`av-${channel.id}-${m.uid}`} className={`position-relative ${index>0 && "ms-n4"} z-${index+2}`}>
                        <Avatar size="60" name={m.name} round={true} src={getProfileUrl(m)} style={{maxWidth: "none"}} 
                            className="img-thumbnail align-self-center p-0"/>
                    
                        <span style={{height: 18, width: 18, right: 0, bottom: -18}} className={`position-absolute border border-2 border-white rounded-circle ${getStatusClass(m)}`}><span className="visually-hidden">{getStatus(m)}</span></span>
                    </span>
                )}
            </div>}
            <div className="flex-grow-1 overflow-hidden align-self-center">
                {!isHeader && <h5 className="text-truncate font-size-14 mb-1">
                    {members.map(m => m.name).join(", ")}
                    {currentUser.claims.admin && <span className="ms-1">[{channel.tag}]</span>}
                </h5>}
                {isHeader && <ul className="ms-2 list-group list-group-horizontal">
                    {members.map((m, index) => 
                        <li className="list-group-item text-truncate h5 border-0 font-size-14 mb-1" key={`an--${channel.id}-${m.uid}`}>
                            <span style={{height: 18, width: 18, left: 0}} className={`position-absolute border border-2 border-white rounded-circle ${getStatusClass(m)}`}><span className="visually-hidden">{getStatus(m)}</span></span>
                            {m.name}
                        </li>
                    )}
                    {currentUser.claims.admin && <li className="list-group-item align-self-center h5 border-0 font-size-14 mb-1" key={`an--${channel.id}-tag`}>[{channel.tag}]</li>}

                </ul>}
                {!isHeader && channel.lastMessage && <p className="text-truncate mb-0">
                    <i><ChatSenderName channel={channel} message={channel.lastMessage} currentUser={currentUser} />
                    {channel.lastMessage.text && ":"}</i>
                    
                    {channel.lastMessage.text ? <span className="ms-1">{channel.lastMessage.text}</span> : " sent an image"}
                </p>}
            </div>
            <div className="font-size-11 align-self-center">
                
            </div>
        </div>
        }
    </>
}

export default ChatAvatar;