import { 
  EDIT_PROFILE_ERROR, 
  EDIT_PROFILE_SUCCESS, 
  EDIT_PROFILE, 
  RESET_PROFILE_FLAG, 
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const editProfileSuccess = msg => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: msg,
  }
}

export const editProfileError = error => {
  return {
    type: EDIT_PROFILE_ERROR,
    payload: error,
  }
}

export const getProfile = () => {
  return {
    type: GET_PROFILE
  }
}

export const getProfileSuccess = (profile) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: profile,
  }
}

export const getProfileError = error => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
